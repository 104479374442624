import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu: []
  },
  getters: {
    getMenu() {
      return localStorage.getItem('MENU')
    }
  },
  mutations: {
    setMenu(state, meun) {
      state.menu = meun
      localStorage.setItem('MENU',JSON.stringify(meun))
    }
  },
  actions: {},
  modules: {},
});
