
import axios from 'axios'
import { requestConfig } from "@/utils/axios-interceptors";

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

const http = axios.create({
  timeout: 1000 * 60,
  withCredentials: true,
});

http.interceptors.request.use(
  config => {
    return requestConfig(config) ;
  },
  error =>{
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response =>{
    return response.data
  },
  error =>{
    return Promise.reject(error);
  }
);
export default http;
