<script>
import { companySwitch, getUserInfo, saveLogout } from "@/api/user";

export default {
  name: "AdminHeader",
  data(){
    return {
      userInfo: {},
      companyData: [],
      value: '',
      dialogVisible: true,
      msgboxValue: ''
    }
  },
  created() {
    const h = this.$createElement;
    if(this.$route.path !=='/about') {
      getUserInfo().then(res => {
        this.companyData = res.companyData;
        this.userInfo = res.member;
        this.value = res.member && res.member.companyCode;


        if(this.userInfo && !this.userInfo.companyCode){
          // this.dialogVisible = true
          // this.$alert('为保证系统正常使用，请于右上方进行信息切换，若无信息，请尽快联系负责人', '信息提示', {
          //   confirmButtonText: '确定',
          // });
          this.$msgbox({
            title: '信息切换',
            message: h('div', null, [
              h('p', {
                style: {
                  margin: '0 0 10px'
                }
              }, '为保证系统正常使用，请进行信息切换，若无信息请尽快联系负责人'),
              h('el-select', {
                ref: 'msgBox',
                on: {
                  input: (val) => {
                    this.msgboxValue = val;
                    this.$refs['msgBox'].value = val
                  }
                },
                props: {
                  value: '',
                  placeholder: '请选择'
                },
                style: {
                  width: '100%'
                }
              }, this.companyData.map(item => {
                return h('el-option', {
                  props: {
                    label: item.name,
                    value: item.code
                  }
                });
              }))
            ]),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            beforeClose: (action,instance, done) => {
              if (action === 'confirm') {
                if(this.companyData.length === 0){
                  this.$message({
                    type: "warning",
                    message: "请选择"
                  })
                  return false
                } else {
                  done();
                }
              } else {
                done();
              }
            }
          }).then(() => {
            companySwitch({
              code: this.msgboxValue
            }).then(res => {
              if(!res.code){
                this.$message({
                  type: "success",
                  message: "切换成功"
                })
                setTimeout(() => {
                  location.reload();
                }, 800)
              }
            })
          }).catch(e => e);
        }
      })
    }
  },
  methods: {
    openMenu() {
      this.$emit("isCollapseMenu");
    },
    handleReload() {
      location.reload();
    },
    handleCommand(command){
      if(command === "logout"){
        this.$confirm("是否确定退出", "系统提示", {
          confirmButtonText:"确定",
          cancelButtonText:"取消"
        }).then(()=>{
          saveLogout().then(() => {
            localStorage.clear();
            this.$router.replace("/login");
          })
        })
          .catch(e => e);
      }
    },
    handleChange(event) {
      companySwitch({
        code: event
      }).then(res => {
        if(!res.code){
          this.$message({
            type: "success",
            message: "切换成功"
          })
          setTimeout(() => {
            location.reload();
          }, 800)
        }
      })
    }
  }
};
</script>

<template>
  <el-header height="64" style="width: 100%" class="el-header">
    <div @click="openMenu" class="el-header-menu-icon trigger">
      <i class="el-icon-s-fold"></i>
    </div>
    <div class="el-header-right u-flex u-flex-xy-center">
      <div class="u-flex u-flex-xy-center" style="margin-right: 10px;cursor: pointer" @click="handleReload">
        <i class="el-icon-refresh" style="font-size: 30px"></i>
        <span style="margin-left: 5px;font-size: 14px">刷新</span>
      </div>
      <div style="margin-right: 10px" v-if="companyData && companyData.length > 0">
        <el-select size="small" v-model="value" placeholder="请选择" @change="handleChange">
          <el-option
            v-for="item in companyData"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
      </div>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <el-avatar v-if="userInfo.photo"  :size="30" :src="$httpUrl  + userInfo.photo"></el-avatar>
          <el-avatar v-else :size="30"></el-avatar>
          <span class="name">{{ userInfo.name }}</span>
        </span>
        <el-dropdown-menu>
          <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </el-header>
</template>

<style scoped lang="scss">
@import "index.scss";
</style>
