<template>
  <el-container>
    <admin-menu :isCollapse="isCollapse" :menu="menu"></admin-menu>
    <el-container style="flex-direction: column;">
      <admin-header @isCollapseMenu="selectMenu"></admin-header>
      <el-main class="el-main">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AdminHeader from "@/layout/header/AdminHeader.vue";
import AdminMenu from "@/layout/menu/AdminMenu.vue";
import { routes } from "@/router";

export default {
  name: "layoutIndex",
  components: { AdminMenu, AdminHeader },
  data() {
    return {
      isCollapse: false,
      menu: []
    }
  },
  created() {
    this.menu = routes
  },
  methods: {
    selectMenu() {
      this.isCollapse = !this.isCollapse
    }
  }
};
</script>

<style scoped lang="scss">
.el-main {
  overflow-x: hidden;
  background: #f0f2f5;
  height: calc(100vh - 68px);
}
</style>
