import http from '@/utils/http'
import { Message, Notification, MessageBox} from "element-ui";
import utils from "@/utils/utils";
import router from "@/router";
import Qs from "qs";
import { saveLogout } from "@/api/user";

let loginCount = 0
export const get = async (url, data, options = {}) => {
  const response = options.formData ? await http.get(url, {data: Qs.stringify(data)}) : await http.get(url, {params: data})
  switch (response.code) {
    case 200:
      if(options.isMessage) {
        Message.success(response.msg || options.message)
      }
      return response.data
    case 201:
      if(loginCount === 0) {
        loginCount++
        await MessageBox.alert(response.msg, '登录提示', {
          confirmButtonText: '确定',
          showClose: false,
          callback: () => {
            saveLogout().then(() => {
              utils.clearStorage()
              router.replace('/login')
            })
          }
        });
      }
      break;
    case 202:
      if(options.isMessage || options.isMessage202) {
        Notification.error({
          title: '错误',
          message: response.msg
        })
      }
      break;
    case 211:
      if(loginCount === 0) {
        loginCount++

        Notification.error({
          title: '登录提示',
          message: '您已在别的浏览器登录！',
          duration: 0
        });

        saveLogout().then(() => {
          utils.clearStorage()
          router.replace('/login')
        })
      }
      break;
  }

  return response
}


export const post = async (url, data, config, options) => {
  const response=  await http.post(url, data, config)
  switch (response.code) {
    case 200:
      if(options && options.isMessage) {
        Message.success(response.msg || options.message)
      }
      return response.data
    case 201:
      if(loginCount === 0) {
        loginCount++
        await MessageBox.alert(response.msg, '登录提示', {
          confirmButtonText: '确定',
          showClose: false,
          callback: () => {
            saveLogout().then(() => {
              utils.clearStorage()
              router.replace('/login')
            })
          }
        });
      }
      break;
    case 202:
      if(options && options.isMessage202) {
        Notification.error({
          title: '错误',
          message: response.msg
        })
      }
      break;
    case 211:
      if(loginCount === 0) {
        loginCount++
        Notification.error({
          title: '登录提示',
          message: '登录失效!账号已在其它地方登录。如非本人操作，请核查！',
          duration: 0
        });

        saveLogout().then(() => {
          utils.clearStorage()
          router.replace('/login')
        })
      }
      break;
  }

  return response
}
